import Flex from '@/components/Flex';
import Typography from '@/components/Typography';

import { GRAY500, GRAY700 } from '@/constant/theme';

interface ErrorProps {
  content: string;
  title: string;
}

const Error = (props: ErrorProps) => {
  const { content, title } = props;

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Flex.Item fullWidth={false}>
        <Typography.H3
          modifier="heading-3"
          margin="32px 0 8px"
          textAlign="center"
          color={GRAY700}
        >
          {title}
        </Typography.H3>
        <Typography.Paragraph
          textAlign="center"
          modifier="body-2"
          color={GRAY500}
        >
          {content}
        </Typography.Paragraph>
      </Flex.Item>
    </Flex>
  );
};

export default Error;

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import SnackbarItem from '@/components/SnackbarItem';

import type { PropsWithRequiredChildren } from '@/types/react';
import type { Maybe } from '@/types/utils';

import {
  DEFAULT_SNACKBAR_DURATION,
  DEFAULT_SNACKBAR_INTERVAL
} from './constant';
import type { SnackbarContextType, SnackbarPayloadType } from './types';

const _SnackbarContext = createContext<Maybe<SnackbarContextType>>(undefined);

/**
 * Snackbar Provider
 *
 * @since 2024.02.07
 */
export const SnackbarProvider = (props: PropsWithRequiredChildren<unknown>) => {
  const { children } = props;
  const [show, toggleShow] = useState(false);
  const [state, setState] = useState<Partial<SnackbarPayloadType>>({});
  const timeoutRef = useRef<Maybe<ReturnType<typeof setInterval>>>();

  const handleOnCloseSnackbar = () => {
    toggleShow(false);

    setTimeout(() => setState({}), DEFAULT_SNACKBAR_INTERVAL);
  };

  const handleShowSnackbar = useCallback(
    (formattedPayload: SnackbarPayloadType) => {
      if (timeoutRef.current) clearInterval(timeoutRef.current);

      setState(formattedPayload);
      toggleShow(true);

      timeoutRef.current = setTimeout(() => {
        handleOnCloseSnackbar();
      }, Number(formattedPayload.duration));
    },
    []
  );

  const handleOnOpenSnackbar = useCallback(
    (payload: SnackbarPayloadType) => {
      const {
        actionLink,
        duration = DEFAULT_SNACKBAR_DURATION,
        icon,
        iconColor,
        message,
        onClickActionLink
      } = payload;

      const formattedPayload: SnackbarPayloadType = {
        actionLink,
        duration,
        icon,
        iconColor,
        message,
        onClickActionLink
      };

      if (state?.message || show) {
        // reset
        toggleShow(false);

        setTimeout(() => {
          handleShowSnackbar(formattedPayload);
        }, DEFAULT_SNACKBAR_INTERVAL);
        return;
      }

      handleShowSnackbar(formattedPayload);
    },
    [handleShowSnackbar, show, state?.message]
  );

  const contextValue = useMemo((): SnackbarContextType => {
    return {
      close: handleOnCloseSnackbar,
      open: handleOnOpenSnackbar
    };
  }, [handleOnOpenSnackbar]);

  return (
    <_SnackbarContext.Provider value={contextValue}>
      {children}

      <SnackbarItem {...state} display={show} onClose={handleOnCloseSnackbar} />
    </_SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const payload = useContext(_SnackbarContext);

  if (!payload)
    throw new Error(`useSnackbar must be used within the SnackbarProvider`);

  return payload;
};

import { keyframes } from '@emotion/react';

export const keyframeRotation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const keyframeFadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const keyframeFadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const keyframeFadeInUp = keyframes`
  0% {
    opacity: 0;
    translate: 0px 100%;
  }

  100% {
    opacity: 1;
    translate: 0px;
  }
`;

export const keyframeFadeOutUp = keyframes`
  0% {
    opacity: 1;
    translate: 0px;
  }

  100% {
    opacity: 0;
    translate: 0px 100%;
  }
`;

export const keyframeBackInUp = keyframes`
  0% {
    translate: 0px 100%;
  }

  100% {
    translate: 0px;
  }
`;

export const keyframeBackOutUp = keyframes`
  0% {
    translate: 0px;
  }

  100% {
    translate: 0px 100%;
  }
`;

export const keyframeBackInRight = keyframes`
  0% {
    translate: 100% 0;
  }

  100% {
    translate: 0 0;
  }
`;

export const keyframeBackOutRight = keyframes`
  0% {
    translate: 0 0;
  }

  100% {
    translate: 100% 0;
  }
`;

export const keyframeShimmer = keyframes`
  0% {
    translate: -100%;
  }

  100% {
    translate: 100%;
  }
`;

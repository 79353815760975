import type { PropsWithChildren } from 'react';

import * as Sentry from '@sentry/react';

import Error from '@/components/Error';

interface SentryErrorBoundaryProps {
  fallbackContent?: string;
  fallbackTitle?: string;
}

const SentryErrorBoundary = (
  props: PropsWithChildren<SentryErrorBoundaryProps>
) => {
  const {
    children,
    fallbackContent = 'An error has occurred',
    fallbackTitle = '500'
  } = props;

  if (
    process.env.REACT_APP_SENTRY_DSN &&
    process.env.REACT_APP_SENTRY_AUTH_TOKEN
  ) {
    return (
      <Sentry.ErrorBoundary
        fallback={<Error title={fallbackTitle} content={fallbackContent} />}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }

  return <>{children}</>;
};

export default SentryErrorBoundary;

import loadable from '@loadable/component';

const CreditCardFormModules = loadable(
  () =>
    import(
      /* webpackChunkName: "credit-card-form-modules" */ './CreditCardForm'
    )
);

export default CreditCardFormModules;

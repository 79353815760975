import loadable from '@loadable/component';

const PaylaterOrderDetailLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "paylater-order-detail-modules" */ './PaylaterOrderDetail'
    )
);

export default PaylaterOrderDetailLazy;

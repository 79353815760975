import loadable from '@loadable/component';

const EWalletOrderDetailLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "e-wallet-order-detail-modules" */ './EWalletOrderDetail'
    )
);

export default EWalletOrderDetailLazy;

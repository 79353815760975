type CXArgs =
  | string
  | { [key: string]: boolean | void | null }
  | Array<CXArgs>
  | void
  | null;

/**
 * Combines multiple class names or objects into a single string.
 *
 * @param  args - The class names or objects to be combined.
 * @returns  A string containing the combined class names.
 */
export const cx = (...args: Array<CXArgs>): string => {
  let cls = '';
  for (let i = 0; i < args.length; i++) {
    const arg = args[i];

    if (arg == null) continue;

    let toAdd;
    switch (typeof arg) {
      case 'object': {
        if (Array.isArray(arg)) {
          toAdd = cx(...arg);
        } else {
          toAdd = '';
          for (const k in arg) {
            if (arg[k] && k) {
              toAdd && (toAdd += ' ');
              toAdd += k;
            }
          }
        }
        break;
      }
      default: {
        toAdd = arg;
      }
    }
    if (toAdd) {
      cls && (cls += ' ');
      cls += toAdd;
    }
  }
  return cls;
};

/////////////////////////////////////////////////////////////////////////////
// Unit Converter Utils
/////////////////////////////////////////////////////////////////////////////

/**
 * Converts a number to a pixel string representation.
 *
 * @param arg - The number to be converted.
 * @returns A string representing the number with 'px' appended.
 */
export const numberToPx = (arg: number) => {
  if (typeof arg === 'number') {
    return `${arg}px`;
  }

  return '';
};

/**
 * Converts a pixel string representation to a number.
 *
 * @param arg - The pixel string to be converted.
 * @returns The parsed number, or 0 if the input is not a valid pixel string.
 */
export const pxToNumber = (arg: string) => {
  if (arg.includes('px')) {
    const result = Number(arg.replace('px', ''));

    if (!Number.isNaN(result)) return result;

    return 0;
  }

  return 0;
};

import loadable from '@loadable/component';

const EWalletTransactionLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "e-wallet-transaction-modules" */ './EWalletTransaction'
    )
);

export default EWalletTransactionLazy;

import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import loadable from '@loadable/component';

import type { CustomUIErrorPayloadType } from '@/model/error';
import type { Maybe, NullAble } from '@/types/utils';

import type { ErrorTransactionRefType } from './types';

const ErrorTransactionDialogLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "error-transaction" */ './ErrorTransactionDialog'
    )
);

const ErrorTransactionDialog = forwardRef<ErrorTransactionRefType>((_, ref) => {
  const [display, toggleDisplay] = useState<NullAble<boolean>>(null);
  const [payload, setPayload] =
    useState<Maybe<CustomUIErrorPayloadType>>(undefined);

  useImperativeHandle(ref, () => {
    return {
      open: (args) => {
        setPayload(args);
        toggleDisplay(true);
      }
    };
  });

  const handleOnClose = useCallback(() => {
    if (payload?.onClose) payload.onClose();

    toggleDisplay(false);
  }, [payload]);

  const handleOnRetry = useCallback(() => {
    if (payload?.onClickActionButton) payload.onClickActionButton();

    toggleDisplay(false);
  }, [payload]);

  if (typeof display === 'boolean' && typeof payload !== 'undefined') {
    return (
      <ErrorTransactionDialogLazy
        {...payload}
        onClickActionButton={handleOnRetry}
        display={display}
        onClose={handleOnClose}
      />
    );
  }

  return null;
});

export default ErrorTransactionDialog;

import loadable from '@loadable/component';

const PaylaterTransactionLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "paylater-transaction-modules" */ './PaylaterTransaction'
    )
);

export default PaylaterTransactionLazy;

import loadable from '@loadable/component';

const VirtualAccountOrderDetailLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "virtual-account-order-detail-modules" */ './VirtualAccountOrderDetail'
    )
);

export default VirtualAccountOrderDetailLazy;

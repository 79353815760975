import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

if (
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_SENTRY_AUTH_TOKEN
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect,
        useLocation,
        useNavigationType
      }),
      Sentry.replayIntegration()
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.2,
    tracePropagationTargets: [],
    tracesSampleRate: 0.2
  });
}

import type { TransitionStatus } from 'react-transition-group';

import type { Interpolation, Theme } from '@emotion/react';

import {
  keyframeBackInRight,
  keyframeBackInUp,
  keyframeBackOutRight,
  keyframeBackOutUp,
  keyframeFadeIn,
  keyframeFadeInUp,
  keyframeFadeOut,
  keyframeFadeOutUp
} from '@/styles/animation.style';

/////////////////////////////////////////////////////////////////////////////
// Transition Constant
/////////////////////////////////////////////////////////////////////////////

export const DEFAULT_TRANSITION = 'cubic-bezier(0.63, 0.01, 0.29, 1)';
export const DEFAULT_DURATION = '300ms';

/////////////////////////////////////////////////////////////////////////////
// Font Constant
/////////////////////////////////////////////////////////////////////////////

export const FONT_FAMILY = 'Inter, Roboto, San Francisco, Arial';

export const FONT_WEIGHT = {
  bold: 700,
  medium: 500,
  normal: 400,
  semibold: 600
} as const;

/////////////////////////////////////////////////////////////////////////////
// Color Constant
/////////////////////////////////////////////////////////////////////////////

// Common Color
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

// Gray Color
export const GRAY100 = '#F3F5F6';
export const GRAY200 = '#E8EAEE';
export const GRAY300 = '#C5C8CD';
export const GRAY400 = '#94979C';
export const GRAY500 = '#54565A';
export const GRAY600 = '#3D424D';
export const GRAY700 = '#2A3040';
export const GRAY800 = '#1A2034';
export const GRAY900 = '#101820';

// Blue Color
export const BLUE100 = '#C6EFF7';
export const BLUE200 = '#90DBF0';
export const BLUE300 = '#54B0D2';
export const BLUE400 = '#297CA6';
export const BLUE500 = '#00416B';
export const BLUE600 = '#00325C';
export const BLUE700 = '#00254D';
export const BLUE800 = '#001A3E';
export const BLUE900 = '#001233';

// Secondary Color
export const SECONDARY100 = '#FFF2C4';
export const SECONDARY200 = '#FFE689';
export const SECONDARY300 = '#FFDB47';
export const SECONDARY400 = '#FFE03F';
export const SECONDARY500 = '#FFD100';
export const SECONDARY600 = '#DBAF00';
export const SECONDARY700 = '#B78F00';
export const SECONDARY800 = '#937000';
export const SECONDARY900 = '#7A5B00';

// Main Color
export const MAIN100 = '#D4F1F3';
export const MAIN200 = '#A7E4E8';
export const MAIN300 = '#74D7DE';
export const MAIN400 = '#51E4DF';
export const MAIN500 = '#1ECAD3';
export const MAIN600 = '#15A0B5';
export const MAIN700 = '#0F7A97';
export const MAIN800 = '#09587A';
export const MAIN900 = '#054065';

// Success Color
export const SUCCESS100 = '#E6FCE9';
export const SUCCESS200 = '#CEF9D7';
export const SUCCESS300 = '#B0EDC3';
export const SUCCESS400 = '#95DCB2';
export const SUCCESS500 = '#71C59C';
export const SUCCESS600 = '#4EAB87';
export const SUCCESS700 = '#388D75';
export const SUCCESS800 = '#388D75';
export const SUCCESS900 = '#155E58';

// Info Color
export const INFO100 = '#D6E6FD';
export const INFO200 = '#ADCCFC';
export const INFO300 = '#83ACF6';
export const INFO400 = '#6290EE';
export const INFO500 = '#3267E3';
export const INFO600 = '#244FC3';
export const INFO700 = '#193AA3';
export const INFO800 = '#0F2783';
export const INFO900 = '#091B6C';

// Warning Color
export const WARNING100 = '#FEF0CC';
export const WARNING200 = '#FEDD9A';
export const WARNING300 = '#FDC567';
export const WARNING400 = '#FBAD41';
export const WARNING500 = '#F98704';
export const WARNING600 = '#D66902';
export const WARNING700 = '#B35002';
export const WARNING800 = '#903901';
export const WARNING900 = '#772A00';

// Danger Color
export const DANGER100 = '#FDEBDF';
export const DANGER200 = '#FCD2C0';
export const DANGER300 = '#F7B29E';
export const DANGER400 = '#F09484';
export const DANGER500 = '#E7655D';
export const DANGER600 = '#C64347';
export const DANGER700 = '#A62E3B';
export const DANGER800 = '#851D31';
export const DANGER900 = '#6E112B';

/////////////////////////////////////////////////////////////////////////////
// Gradient Icon Constant
/////////////////////////////////////////////////////////////////////////////

export const DANGER_GRADIENT = `linear-gradient(
  180deg,
  ${DANGER500} 0%,
  #D64A41 100%
)`;

export const SUCCESS_GRADIENT = `linear-gradient(
  180deg,
  ${SUCCESS500} 0%,
  #329F6A 100%
)`;

export const WARNING_GRADIENT = `linear-gradient(
  180deg,
  ${WARNING500} 0%,
  #E27108 100%
)`;

export const INFO_GRADIENT = `linear-gradient(
  180deg,
  #5080F2 0%,
  #1E4FC1 100%
)`;

/////////////////////////////////////////////////////////////////////////////
// CSS Transition Section
/////////////////////////////////////////////////////////////////////////////

export const BACK_IN_UP_TRANSITION: Partial<
  Record<TransitionStatus, Interpolation<Theme>>
> = {
  entered: { animationDuration: '250ms', animationName: keyframeBackInUp },
  entering: { animationDuration: '250ms', animationName: keyframeBackInUp },
  exited: { animationDuration: '250ms', animationName: keyframeBackOutUp },
  exiting: { animationDuration: '250ms', animationName: keyframeBackOutUp }
};

export const BACK_IN_RIGHT_TRANSITION: Partial<
  Record<TransitionStatus, Interpolation<Theme>>
> = {
  entered: { animationDuration: '250ms', animationName: keyframeBackInRight },
  entering: { animationDuration: '250ms', animationName: keyframeBackInRight },
  exited: { animationDuration: '250ms', animationName: keyframeBackOutRight },
  exiting: { animationDuration: '250ms', animationName: keyframeBackOutRight }
};

export const FADE_IN_UP_TRANSITION: Partial<
  Record<TransitionStatus, Interpolation<Theme>>
> = {
  entered: { animationDuration: '250ms', animationName: keyframeFadeInUp },
  entering: { animationDuration: '250ms', animationName: keyframeFadeInUp },
  exited: { animationDuration: '250ms', animationName: keyframeFadeOutUp },
  exiting: { animationDuration: '250ms', animationName: keyframeFadeOutUp }
};

export const FADE_IN_TRANSITION: Partial<
  Record<TransitionStatus, Interpolation<Theme>>
> = {
  entered: { animationDuration: '200ms', animationName: keyframeFadeIn },
  entering: { animationDuration: '200ms', animationName: keyframeFadeIn },
  exited: { animationDuration: '150ms', animationName: keyframeFadeOut },
  exiting: { animationDuration: '150ms', animationName: keyframeFadeOut }
};

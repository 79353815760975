import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';

import ErrorTransactionDialog from '@/components/ErrorTransactionDialog';
import type { ErrorTransactionRefType } from '@/components/ErrorTransactionDialog/types';

import type { Emitter } from '@/utils/emitter';
import mitt from '@/utils/emitter';
import type { CustomUIErrorPayloadType } from '@/model/error';
import type { PropsWithRequiredChildren } from '@/types/react';
import type { Maybe } from '@/types/utils';

import type { AppContextType, AppEmitterEventType } from './types';

const _AppContext = createContext<Maybe<AppContextType>>(undefined);

interface AppProviderProps {
  emitter?: Emitter<AppEmitterEventType>;
}

export const AppProvider = (
  props: PropsWithRequiredChildren<AppProviderProps>
) => {
  const { children, emitter: _emitter = mitt<AppEmitterEventType>() } = props;
  const emitter = useRef(_emitter);
  const errorTransactionRef = useRef<ErrorTransactionRefType>(null);

  const handleOnShowErrorTransaction = useCallback(
    (args: CustomUIErrorPayloadType) => {
      if (errorTransactionRef.current) {
        errorTransactionRef.current.open(args);
      }
    },
    []
  );

  useEffect(() => {
    emitter.current.on(
      '@shared/show-error-dialog',
      handleOnShowErrorTransaction
    );

    return () => {
      // INFO: disable eslint warn since ref is mutable
      // eslint-disable-next-line react-hooks/exhaustive-deps
      emitter.current.off(
        '@shared/show-error-dialog',
        handleOnShowErrorTransaction
      );
    };
  }, [emitter, handleOnShowErrorTransaction]);

  const contextValue = useMemo<AppContextType>(
    () => ({ emitter: emitter.current }),
    []
  );

  return (
    <>
      <_AppContext.Provider value={contextValue}>
        {children}
      </_AppContext.Provider>

      {/* Dialog & Bottom Sheet Section */}

      <ErrorTransactionDialog ref={errorTransactionRef} />
    </>
  );
};

export const useApp = () => {
  const payload = useContext(_AppContext);

  if (!payload) throw new Error(`useApp must be used within the AppProvider`);

  return payload;
};

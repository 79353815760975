import loadable from '@loadable/component';

const VirtualAccountTransactionLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "virtual-account-transaction-modules" */ './VirtualAccountTransaction'
    )
);

export default VirtualAccountTransactionLazy;

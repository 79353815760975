import { createBrowserRouter, createHashRouter } from 'react-router-dom';

import CreditCardForm from '@/modules/CreditCardForm';
import { ERROR_ROUTES } from '@/modules/Error';
import { ORDER_DETAIL_ROUTES } from '@/modules/OrderDetail';
import { TRANSACTION_ROUTES } from '@/modules/Transaction';

import SentryErrorBoundary from '@/components/SentryErrorBoundary';

const instance =
  process.env.IS_GH_PAGES === 'true' ? createHashRouter : createBrowserRouter;

const router = instance([
  ...ERROR_ROUTES,
  {
    element: (
      <SentryErrorBoundary>
        <CreditCardForm />
      </SentryErrorBoundary>
    ),
    path: '/request-transaction/credit-card'
  },
  ...TRANSACTION_ROUTES,
  ...ORDER_DETAIL_ROUTES
]);

export default router;

import loadable from '@loadable/component';

const CreditCardOrderDetailLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "credit-card-order-detail-modules" */ './CreditCardOrderDetail'
    )
);

export default CreditCardOrderDetailLazy;

import loadable from '@loadable/component';

const OldTransactionLazy = loadable(
  () =>
    import(
      /* webpackChunkName: "old-transaction-error-modules" */ './OldTransaction'
    )
);

export default OldTransactionLazy;

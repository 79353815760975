import type { RouteObject } from 'react-router-dom';

import NotFoundError from './submodules/404';
import OldTransaction from './submodules/OldTransaction';

export const ERROR_ROUTES: RouteObject[] = [
  {
    element: <NotFoundError />,
    path: '*'
  },
  {
    element: <OldTransaction />,
    path: '/old-transaction'
  }
];

import type { RouteObject } from 'react-router-dom';

import SentryErrorBoundary from '@/components/SentryErrorBoundary';

import EWalletTransaction from './submodules/EWalletTransaction';
import PaylaterTransaction from './submodules/PaylaterTransaction';
import VirtualAccountTransaction from './submodules/VirtualAccountTransaction';

export const TRANSACTION_ROUTES: RouteObject[] = [
  {
    element: (
      <SentryErrorBoundary>
        <EWalletTransaction />
      </SentryErrorBoundary>
    ),
    path: '/transaction/e-wallet'
  },
  {
    element: (
      <SentryErrorBoundary>
        <VirtualAccountTransaction />
      </SentryErrorBoundary>
    ),
    path: '/transaction/virtual-account'
  },
  {
    element: <PaylaterTransaction />,
    path: '/transaction/paylater'
  }
];

// INFO: Sentry initialization should be imported first!
// eslint-disable-next-line
import './utils/sentry';

import { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { Global } from '@emotion/react';

import { AppProvider } from './context/AppContext';
import type { AppEmitterEventType } from './context/AppContext/types';
import { SnackbarProvider } from './context/Snackbar';
import { styGlobal } from './styles/global.style';
import mitt from './utils/emitter';
import { safeStringifyJSON } from './utils/parse';
import router from './routes';

const root = createRoot(document.getElementById('root') as HTMLElement);
const emitter = mitt<AppEmitterEventType>();

window.__APP_VERSION = `${process.env.APP_VERSION}`;
window.__VERSION = `${process.env.COMMIT_HASH}`;

/////////////////////////////////////////////////////////////////////////////
// Receive Message Handler
/////////////////////////////////////////////////////////////////////////////

window.receiveMessage = (arg) => {
  try {
    const { action, payload } = JSON.parse(arg);
    emitter.emit('@shared/get-data-from-postmessage', {
      action,
      payload
    });
  } catch {
    // do nothing
  }
};

/////////////////////////////////////////////////////////////////////////////
// Send Message Handler
/////////////////////////////////////////////////////////////////////////////

const _fn = (arg: IframePostDataType) => {
  try {
    const payload = safeStringifyJSON(arg);

    if (
      window.sendMessage &&
      typeof window.sendMessage.postMessage === 'function'
    ) {
      window.sendMessage.postMessage(payload);
    }
  } catch {
    // do nothing
  }
};

try {
  emitter.on('@shared/send-postmessage-into-iframe', _fn);
} catch {
  emitter.off('@shared/send-postmessage-into-iframe', _fn);
}

/////////////////////////////////////////////////////////////////////////////
// Render React Apps
/////////////////////////////////////////////////////////////////////////////

root.render(
  <Fragment>
    <Global styles={styGlobal} />

    <AppProvider emitter={emitter}>
      <SnackbarProvider>
        <section aria-label="container" className="app-container">
          <RouterProvider router={router} />
        </section>
      </SnackbarProvider>
    </AppProvider>
  </Fragment>
);

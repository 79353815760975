import type { RouteObject } from 'react-router-dom';

import SentryErrorBoundary from '@/components/SentryErrorBoundary';

import CreditCardOrderDetail from './submodules/CreditCardOrderDetail';
import EWalletOrderDetail from './submodules/EWalletOrderDetail';
import PaylaterOrderDetail from './submodules/PaylaterOrderDetail';
import VirtualAccountOrderDetail from './submodules/VirtualAccountOrderDetail';

export const ORDER_DETAIL_ROUTES: RouteObject[] = [
  {
    element: (
      <SentryErrorBoundary>
        <CreditCardOrderDetail />
      </SentryErrorBoundary>
    ),
    path: '/order-detail/credit-card'
  },
  {
    element: (
      <SentryErrorBoundary>
        <EWalletOrderDetail />
      </SentryErrorBoundary>
    ),
    path: '/order-detail/e-wallet'
  },
  {
    element: (
      <SentryErrorBoundary>
        <VirtualAccountOrderDetail />
      </SentryErrorBoundary>
    ),
    path: '/order-detail/virtual-account'
  },
  {
    element: <PaylaterOrderDetail />,
    path: '/order-detail/paylater'
  }
];
